class Api {
  VERSION = 'v1';
  APP_TOKEN = 'abc123';

  BASE_URL = 'https://api.attrexacademy.com';
  // BASE_URL = 'http://127.0.0.1:8000';

  URL = this.BASE_URL + '/api/';

  //admin
  ADMIN_LOGIN = this.URL + 'admin/login';
  GET_ADMIN = this.URL + 'admins';
  ADD_ADMIN = this.URL + 'admin/add';
  UPDATE_ADMIN = this.URL + 'admin/update/';
  DELETE_ADMIN = this.URL + 'admin/delete/';

  //courses
  GET_COURSES = this.URL + 'course';
  ADD_COURSES = this.URL + 'course/add';
  UPDATE_COURSES = this.URL + 'course/update/';
  DELETE_COURSES = this.URL + 'course/delete/';

  //course levels
  GET_COURSE_LEVELS = this.URL + 'course/levels';
  ADD_COURSE_LEVEL = this.URL + 'course/level/add';
  UPDATE_COURSE_LEVEL = this.URL + 'course/level/update/';
  DELETE_COURSE_LEVEL = this.URL + 'course/level/delete/';

  //course tokens
  GET_COURSE_TOKENS = this.URL + 'course/tokens';
  ADD_COURSE_TOKEN = this.URL + 'course/token/add';
  UPDATE_COURSE_TOKEN = this.URL + 'course/token/update/';
  DELETE_COURSE_TOKEN = this.URL + 'course/token/delete/';

  //lecturers
  GET_LECTURER = this.URL + 'lecturer';
  ADD_LECTURER = this.URL + 'lecturer/add';
  UPDATE_LECTURER = this.URL + 'lecturer/update/';
  DELETE_LECTURER = this.URL + 'lecturer/delete/';

  //videos
  GET_VIDEOS = this.URL + 'videos';
  ADD_VIDEO = this.URL + 'video/add';
  UPDATE_VIDEO = this.URL + 'video/update/';
  DELETE_VIDEO = this.URL + 'video/delete/';

  //file upload
  FILES_UPLOAD = this.URL + 'files/upload';

  //student
  GET_STUDENTS = this.URL + 'student/get';
  ADD_STUDENT = this.URL + 'student/create';
  EDIT_STUDENT = this.URL + 'student/update/';
  ASSIGN_STUDENT = this.URL + 'course/assign/student';

  //materials
  GET_MATERIALS = this.URL + 'materials';
  ADD_MATERIAL = this.URL + 'material/add';
  UPDATE_MATERIAL = this.URL + 'material/update/';
  DELETE_MATERIAL = this.URL + 'material/delete/';

  //live sessions
  GET_LIVE_SESSIONS = this.URL + 'lives';
  ADD_LIVE_SESSION = this.URL + 'live/add';
  UPDATE_LIVE_SESSION = this.URL + 'live/update/';
  DELETE_LIVE_SESSION = this.URL + 'live/delete/';

  //live meetings
  GET_LIVE_MEETINGS = this.URL + 'meetings';
  ADD_LIVE_MEETING = this.URL + 'meeting/add';
  UPDATE_LIVE_MEETING = this.URL + 'meeting/update/';
  DELETE_LIVE_MEETING = this.URL + 'meeting/delete/';

  //payments
  GET_PAYMENTS = this.URL + 'payments';
}

export default new Api();
