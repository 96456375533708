import jwt_decode from 'jwt-decode';
import api from './Api';

export const baseUrl = () => {
  return api.BASE_URL + '/';
}

export const setUserDetails = (data) => {
  let userDetails = {
    user_id: data.id,
    user_name: data.user_name,
    // created: data.created_at,
  }
  localStorage.setItem('sp-admin.user-details', JSON.stringify(userDetails));
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem('sp-admin.user-details'));
}

//session token authentication
export const setToken = (token) => {
  localStorage.setItem('sp-admin.session-token', token);
}

export const setIsLogged = (status) => {
  localStorage.setItem('sp-admin.is-logged', status);
}

export const getIsLogged = () => {
  return localStorage.getItem('sp-admin.is-logged') || false;
}

export const checkLogin = () => {
  var jwt = window.localStorage.getItem('sp-admin.session-token');
  if (jwt && getIsLogged()) {
    var decoded = jwt_decode(jwt);
    return Date.now() / 1000 <= decoded.exp;
  } else {
    return false;
  }
}

export const logout = () => {
  if(getIsLogged()){
    localStorage.clear();
    window.location.reload();
  }
}
//session token authentication
